import React, {useCallback, useMemo, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cx from 'classnames';
import {useSigner} from 'wagmi';
import { LiFiWidget } from '@lifi/widget';

import {Heap} from '@fidi/concrete/store';

import {updateHeap} from '@/actions';
import {ThemeContext, THEMES} from '@/app/contexts/theme';
import {disconnect, startConnecting} from '@/actions/connect';
import Loader from '@/uikit/components/Loader';

import LiFiEvents from './components/LiFiEvents';
import * as styles from './styles.styl';
import {normalizeChainToken} from './helpers';

const View = () => {
  const { data: signer } = useSigner();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const connectedWalletId = useSelector((state: ({heap: Heap})) => state.heap.walletId);
  const isVisible = useSelector((state: {heap: Heap}) => state.heap.swap.isVisible);
  const fromChain = useSelector((state: {head: Heap}) => state.heap.swap.fromChain);
  const fromToken = useSelector((state: {head: Heap}) => state.heap.swap.fromToken);
  const {theme} = useContext(ThemeContext);
  const onConnect = useCallback(() => dispatch(startConnecting()), [dispatch, startConnecting]);
  const onDisconnect = useCallback(() => dispatch(disconnect()), [dispatch, disconnect]);
  useEffect(() => {
    if (!isVisible) return;
    setIsLoading(true);
    close();
    // Omg here is the problem with updating button connection status
    // status doesn't updating without rerender. Key is not working
    setTimeout(() => {
      open();
    }, 0);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [connectedWalletId])
  const walletManagementConnect = useCallback(() => {
    onConnect();
    return signer;
  }, [onConnect, signer])

  const widgetConfig = useMemo(() => {
    const config = {
      theme: {
        palette: {
          primary: { main: '#7e8bff' },
          secondary: { main: '#f07eff' },
        },
        shape: {
          borderRadius: 16,
          borderRadiusSecondary: 16,
        },
        typography: {
          fontFamily: 'Gilroy',
        },
      },
      fee: 0.003,
      appearance: theme === THEMES.DARK ? 'dark' : 'light',
      hiddenUI: ['appearance'],
      walletManagement: {
        signer,
        connect: walletManagementConnect,
        disconnect: onDisconnect,
      }
    };
    if (fromChain && fromToken) {
      return Object.assign(
        {},
        config,
        {
          fromChain,
          fromToken: normalizeChainToken(fromChain, fromToken),
        }
      )
    }
  }, [fromChain, fromToken, normalizeChainToken, theme, signer, onDisconnect, walletManagementConnect]);
  const preventDefault = (e) => {
    e.cancelBubble = true;
    e.stopPropagation();
  }
  const close = useCallback(() => dispatch(updateHeap({swap: {isVisible: false}})), [dispatch, updateHeap]);
  const open = useCallback(() => dispatch(updateHeap({swap: {isVisible: true, fromChain, fromToken}})), [dispatch, updateHeap, fromChain, fromToken]);

  return (
    <div className={cx(styles.root, {[styles.visible]: isVisible || isLoading})} onClick={close}>
      <div className={styles.contentWrapper}>
        <div className={styles.content} onClick={preventDefault}>
          <LiFiEvents />
          <LiFiWidget integrator="FiDi" config={widgetConfig} />
          {isLoading && <div className={styles.loader}><Loader /></div>}
        </div>
        <div className={styles.disclaimer}>
          For technical support, please visit the LI.FI <a href="https://discord.gg/lifi" target="_blank">Discord</a> server
        </div>
      </div>
    </div>
  );
}

export default View;