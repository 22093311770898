import {createAction} from '@reduxjs/toolkit';

import {Heap} from '../store';
import {WidgetGroupId} from '../types/widgetGroup';
import {NotificationRaw} from '../entities/notification';

export const updateCollections = createAction<Record<string, Record<string, any>>>('@global/UPDATE_COLLECTIONS');
export const updateHeap = createAction<Heap>('@global/UPDATE_HEAP');
export const updateWidgetsGroup = createAction<WidgetGroupId>('@global/UPDATE_WIDGETS_GROUP');
export const notify = createAction<NotificationRaw>('@global/NOTIFY');
