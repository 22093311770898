import React from 'react';
import {useSelector} from 'react-redux';

import {selectNotificationsByIds} from '@fidi/concrete/entities/notification/selectors';
import type {Notification as NotificationType} from '@fidi/concrete/entities/notification';

import Notification from './components/Notification';
import type {Data, State} from '.';
import * as styles from './styles.styl';

const Notifications = ({notificationIds}: Data) => {
  const notifications = useSelector<State, NotificationType[]>((state: State) =>
    selectNotificationsByIds(state, notificationIds)
  );
  return (
    <div className={styles.root}>
      {notifications.map(notification =>
        <Notification {...notification} key={notification.id} />
      )}
    </div>
  )
}

export default Notifications;