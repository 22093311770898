import { schema } from 'normalizr';
import {asset, assetChain, assetProtocol, namedAsset} from '../asset/schema';

export const allocation = new schema.Entity('allocation', {
  assets: [assetChain],
}, {
  processStrategy: value => ({
    id: value.id,
    assets: value.chains,
    values: value.chains
      .map(({amount}: {amount: number}) => amount),
  }),
});

export const tokenAllocation = new schema.Entity('allocation', {
  assets: [asset]
}, {
  processStrategy: value => ({
    id: value.id,
    assets: value.tokens,
    values: value.tokens
      .map(({amount}: {amount: number}) => amount),
  })
});

export const protocolAllocation = new schema.Entity('allocation', {
  assets: [assetProtocol]
}, {
  processStrategy: value => ({
    id: value.id,
    assets: value.protocols,
    values: value.protocols
      .map(({amount}: {amount: number}) => amount),
  })
});

export const namedAllocation = new schema.Entity('allocation', {
  assets: [namedAsset]
}, {
  processStrategy: value => {
    return {
      id: value.id,
      assets: value.assets,
      values: value.assets
        .map(({value}: {value: number}) => value),
    };
  }
})
