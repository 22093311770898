import React, {useCallback, useState} from 'react';

import Cross from '@/uikit/icons/cross.svg';

import * as styles from './styles.styl';

const StaticTopNotificationBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const close = useCallback(e => {
    setIsVisible(false);
    e.cancelBubble = true;
    e.stopPropagation();
  }, []);
  const onClick = useCallback(() => window.open('https://x.com/cryptofidi/status/1707415092072239601?s=46&t=ibK7Em5-Gzz-pi-z9BeUJA', '_blank'), []);
  if (!isVisible) {
    return null;
  }
  return (
    <div className={styles.root}>
      <p>LIFI is currently experiencing an ongoing vulnerability. Please verify your approvals.
        Read more <a className={styles.link} href="https://x.com/lifiprotocol/status/1813207291778215955" target="_blank">
          here
      </a>.</p>
      <div className={styles.icon} onClick={close}>
        <Cross />
      </div>
    </div>
  )
}

export default StaticTopNotificationBanner;