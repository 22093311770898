import {schema} from 'normalizr';
import {asset} from '../../asset/schema';
import {USD_ID} from '../../currency/constants';

export const assetListItem = new schema.Entity('assetListItem', {
  assetIds: [asset],
}, {
  idAttribute: (value) => `${value.id}-${value.chain}`,
  processStrategy: value => {
    const assets = [{
      id: `${value.id}-${value.chain}`,
      symbol: value.optimized_symbol || value.display_symbol || value.symbol,
      name: value.name,
      price: value.price,
      priceSource: value.priceSource,
      logoUrl: value.logoUrl,
      isHidden: value.isHidden,
      chain: value.chain,
      chainId: value.chainId,
      address: value.address,
    }];

    return {
      id: `${value.id}-${value.chain}`,
      assetIds: assets,
      value: value.price && value.amount ? {[USD_ID]: value.price * value.amount} : null,
      balance: {
        [assets[0].id]: value.amount,
      },
      createdAt: value.time_at,
    };
  },
});
