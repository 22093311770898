import * as React from 'react';

import * as styles from './styles.styl';

const Loader = () =>
    <div className={styles.root}>
        <div/>
        <div/>
        <div/>
        <div/>
    </div>;

export default Loader;