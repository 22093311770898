import {schema} from 'normalizr';

import {tableDataColumn} from '../../tableDataColumn/schemas/default';
import {tableDataRow} from '../../tableDataRow/schemas/collators';

export const COLUMN_CODES = {
  APY: 'APY',
  RANK: 'Rank',
  COLLATORS: 'Collators',
  NOMINATOR: 'Nominator Rank',
  BALANCE: 'Balance',
  VALUE: 'Value',
}

export const COLUMNS = {
  RANK: {
    id: 'collators-data-rank',
    code: COLUMN_CODES.RANK,
  },
  COLLATORS: {
    id: 'collators-data-collators',
    code: COLUMN_CODES.COLLATORS,
  },
  NOMINATOR: {
    id: 'collators-data-nominator-rank',
    code: COLUMN_CODES.NOMINATOR
  },
  APY: {
    id: 'collators-data-apy',
    code: COLUMN_CODES.APY,
  },
  BALANCE: {
    id: 'collators-data-nominator-balance',
    code: COLUMN_CODES.BALANCE
  },
  VALUE: {
    id: 'collators-data-nominator-value',
    code: COLUMN_CODES.VALUE,
  },
};

export const tableData = new schema.Entity('tableData', {
  columnIds: [tableDataColumn],
  rowIds: [tableDataRow]
}, {
  idAttribute: value => `tableData-${value.id}`,
  processStrategy: value => ({
      id: `tableData-${value.id}`,
      columnIds: Object.values(COLUMNS),
      rowIds: value.collators,
  }),
});