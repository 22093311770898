import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useAccount, useConnect} from 'wagmi';
import {ConnectArgs, ConnectorNotFoundError} from '@wagmi/core';
import {getWallets, NotInstalledError, WalletAccount} from '@talismn/connect-wallets';
import { useLocation } from 'react-router-dom';

import {WalletId} from '@fidi/concrete/entities/wallet';

import {updateHeap} from '@/actions';
import {EVENT_CATEGORIES, gtagEvent} from '@/utils/gtag';
import Module from '@/uikit/components/Module';
import Wallet from '@/uikit/icons/wallet.svg';
import {prepareWalletId} from '@/utils/wallet';
import usePrevious from '@/utils/hooks/usePrevious';

import Item from './components/Item';
import {DAPP_NAME, PolkaWalletExtension} from '../../constants';
import {getRedirectLocation} from '../../helpers';
import {PhantomProvider} from './wallets/Phantom/phantomProvider';
import {getProvider} from './wallets/Phantom/getProvider';
import MetamaskIcon from './icons/metamask.svg';
import CoinbaseIcon from './icons/coinbase.svg';
import WalletConnect from './icons/walletConnect.svg';
import * as styles from './styles.styl';

type ConnectorProps = {
  name: string,
  description?: string,
  onDescriptionClick?: (e?: React.MouseEvent<HTMLElement>) => void,
  icon: React.ReactNode,
  onError?: (e?: React.MouseEvent<HTMLElement>) => void,
};

type Props = {
  close: () => void,
}

const View = ({close}: Props) => {
  const location = useLocation();
  const [provider, setProvider] = useState<PhantomProvider | undefined>(undefined);
  const [trustedWalletExtensions, setTrustedWalletExtensions] = useState<PolkaWalletExtension[]>([]);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('trustedWalletExtensions'));
    if (items) {
      setTrustedWalletExtensions(items);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('trustedWalletExtensions', JSON.stringify(trustedWalletExtensions));
    const provider = getProvider();
    if (provider) setProvider(provider);
    setTimeout(async () => {
      const walletId = await getConnectedWalletId();
      if (walletId) {
        dispatch(updateHeap({
          walletId,
        }));
      }
    }, 300)
  }, [trustedWalletExtensions]);

  const dispatch = useDispatch();
  const { address } = useAccount();
  const { connect, connectors } = useConnect({
    onSuccess(args) {
      const {account, connector} = args;
      gtagEvent({
        name: 'Connection.Success',
        category: EVENT_CATEGORIES.CONNECTION,
        label: 'Was connected',
        value: 1,
        wallet_id: account,
        type: connector.id
      })
      updateHeapWalletId(account);
    },
    onError(e, args ) {
      if (e instanceof ConnectorNotFoundError) {
        const connector = connectorsProps[args.connector.id];
        connector?.onError?.();
      }
    }
  })

  const _connect = useCallback((args?: Partial<ConnectArgs> | undefined) => {
    if (!args) return;
    gtagEvent({
      name: 'Connection.Initiation',
      category: EVENT_CATEGORIES.CONNECTION,
      label: 'Initiated connection',
      value: 1,
      type: args.connector.id,
    })
    return connect(args)
  }, [connect])

  const updateHeapWalletId = useCallback((rawWalletId: WalletId) => {
    const walletId = prepareWalletId(rawWalletId);
    dispatch(updateHeap({
      walletId,
      externalWalletId: walletId,
    }));
    close();
    if (walletId) {
      const redirectUrl = getRedirectLocation(location, walletId);
      if (redirectUrl) {
        window.location.replace(redirectUrl);
      }
    }
  }, [location]);

  const connectNative = async (extensionName: PolkaWalletExtension) => {
    const wallets = await getWallets();
    const wallet = wallets.find(walletExtension =>
      walletExtension.extensionName === extensionName
    );
    if (!wallet) {
      return;
    }
    try {
      await wallet.enable(DAPP_NAME);
    } catch (e) {
      if (e instanceof NotInstalledError) {
        gtagEvent({
          name: 'Connection.Initiation.Failed',
          category: EVENT_CATEGORIES.CONNECTION,
          label: `There isn\'t extension`,
          value: 1,
          type: extensionName,
        })
        window.open(wallet.installUrl);
      }
    }
    const accounts = await wallet.getAccounts();
    if (accounts.length) {
      setTrustedWalletExtensions([...trustedWalletExtensions, extensionName]);
      updateHeapWalletId(accounts[0].address);
      gtagEvent({
        name: 'Connection.Success',
        category: EVENT_CATEGORIES.CONNECTION,
        label: 'Was connected',
        value: 1,
        type: extensionName,
      })
    }
  }
  const connectSubwallet = connectNative.bind(this, PolkaWalletExtension.SUBWALLET);
  const connectTalisman = connectNative.bind(this, PolkaWalletExtension.TALISMAN);
  const connectPolkadot = connectNative.bind(this, PolkaWalletExtension.POLKADOT);

  const connectPhantom = async () => {
    // @ts-ignore
    const solana = window.phantom?.solana;

    if (!provider || !solana) {
      gtagEvent({
        name: 'Connection.Initiation.Failed',
        category: EVENT_CATEGORIES.CONNECTION,
        label: 'There isn\'t phantom extension',
        value: 1,
        type: 'phantom',
      })
      openPhantomDownloadPage();
      return;
    }

    gtagEvent({
      name: 'Connection.Initiation',
      category: EVENT_CATEGORIES.CONNECTION,
      label: 'Initiated connection',
      value: 1,
      type: 'phantom',
    })
    try {
      const response = await solana.connect();
      // @ts-ignore
      gtagEvent({
        name: 'Connection.Success',
        category: EVENT_CATEGORIES.CONNECTION,
        label: 'Was connected',
        value: 1,
        type: 'phantom',
      })
      updateHeapWalletId(response.publicKey.toString());
    } catch (err) {
      // { code: 4001, message: 'User rejected the request.' }
    }
  };

  const openPhantomDownloadPage = (e?: React.MouseEvent<HTMLElement>) => {
    window.open('https://phantom.app/download');
    if (e) {
      e.stopPropagation();
    }
  }
  const openSubwalletDownloadPage = (e?: React.MouseEvent<HTMLElement>) => {
    window.open('https://subwallet.app/download.html');
    if (e) {
      e.stopPropagation();
    }
  }
  const openMetamaskDownloadPage = (e?: React.MouseEvent<HTMLElement>) => {
    window.open('https://metamask.io/download/');
    if (e) {
      e.stopPropagation();
    }
  }
  const openTalismanDownloadPage = (e?: React.MouseEvent<HTMLElement>) => {
    window.open('https://talisman.xyz/');
    if (e) {
      e.stopPropagation();
    }
  }

  const connectorsProps: Record<string, ConnectorProps> = useMemo(() => {
    return {
      metaMask: {
        name: 'Metamask',
        icon: <MetamaskIcon />,
        onError: openMetamaskDownloadPage,
      },
      coinbaseWallet: {
        name: 'Coinbase wallet',
        icon: <CoinbaseIcon />,
      },
      walletConnect: {
        name: 'WalletConnect',
        icon: <WalletConnect />,
      },
      subwallet: {
        name: 'SubWallet',
        icon: <img alt="subWallet" className={styles.subWalletIcon} />,
        onError: openSubwalletDownloadPage,
      },
      talisman: {
        name: 'Talisman',
        icon: <img alt="talisman" className={styles.talismanIcon} />,
        onError: openTalismanDownloadPage,
      }
    }
  }, [openMetamaskDownloadPage]);

  const getConnectedWalletId = async () => {
    // @ts-ignore
    const solana = window.phantom?.solana;

    const wallets = await getWallets();
    const failedExtensions: PolkaWalletExtension[] = [];
    const activeExtensions = trustedWalletExtensions.map(walletExtension => {
      const wallet = wallets.find(w => w.extensionName === walletExtension);
      if (wallet) {
        return wallet.enable('FiDi')
          // @ts-ignore
          .then(() => wallet.getAccounts())
          .then((accounts: WalletAccount[]) => accounts.length
            ? Promise.resolve(accounts[0].address)
            : Promise.reject()
          ).catch(() => {
            failedExtensions.push(walletExtension);
            return Promise.reject();
          })
      }
      return Promise.reject();
    });

    return Promise.any([
      address ? Promise.resolve(prepareWalletId(address)) : Promise.reject(),
      // @ts-ignore
      solana ? solana.connect({ onlyIfTrusted: true }).then(c => c.publicKey.toString()) : Promise.reject(),
      ...activeExtensions,
    ]).finally(() => {
      if (failedExtensions.length) {
        setTrustedWalletExtensions(trustedWalletExtensions.filter(_w => !failedExtensions.includes(_w)))
      }
    }).catch(() => null);
  }

  // change wallet from MM
  const prevAddress = usePrevious({address});
  useEffect(() => {
    if (prevAddress?.address) {
      updateHeapWalletId(address)
    }
  }, [address]);

  return (
    <div className={styles.root}>
      <Module rounded={false}>
        <div className={styles.header}>
          <div className={styles.icon}>
            <Wallet />
          </div>
          <div className={styles.title}>
            Connect wallet
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.column}>
            {connectors.map((connector) => (
              <Item
                key={connector.id}
                onClick={() => _connect({connector})}
                // @ts-ignore
                {...connectorsProps[connector.id]}
              />
            ))}
          </div>
          <div className={styles.column}>
            <Item
              name="Phantom"
              onClick={connectPhantom}
              onDescriptionClick={openPhantomDownloadPage}
              icon={<img alt="phantom" className={styles.phantomIcon} />}
            />
            <Item
              name="Polkadot.js"
              onClick={connectPolkadot}
              icon={<img alt="polkadotjs" className={styles.polkadotIcon} />}
            />
            <Item
              name="SubWallet (Native)"
              onClick={connectSubwallet}
              icon={<img alt="subWallet" className={styles.subWalletIcon} />}
            />
            <Item
              name="Talisman (Native)"
              onClick={connectTalisman}
              icon={<img alt="talisman" className={styles.talismanIcon} />}
            />
          </div>
        </div>
      </Module>
    </div>
  );
};

export default View;