import ArrowDown from './arrowDown.svg';
import ArrowLeft from './arrowLeft.svg';
import ArrowRight from './arrowRight.svg';
import ArrowUp from './arrowUp.svg';
import ArrowUpRight from './arrowUpRight.svg';
import Book from './book.svg';
import Bookmark from './bookmark.svg';
import Burger from './burger.svg';
import Compass from './compass.svg';
import DappRadar from './dappradar.svg';
import Discord from './discord.svg';
import Exit from './exit.svg';
import FlatStack from './flatStack.svg';
import Gift from './gift.svg';
import Globe from './globe.svg';
import Hourglass from './hourglass.svg';
import LinkedIn from './linkedin.svg';
import Picture from './picture.svg';
import Reddit from './reddit.svg';
import Telegram from './telegram.svg';
import Triangle from './triangle.svg';
import Twitter from './twitter.svg';
import Wallet from './wallet.svg';

export const IconNames = {
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  ArrowUp: 'ArrowUp',
  ArrowUpRight: 'ArrowUpRight',
  Book: 'Book',
  Bookmark: 'Bookmark',
  Burger: 'Burger',
  Compass: 'Compass',
  DappRadar: 'DappRadar',
  Discord: 'Discord',
  Exit: 'Exit',
  FlatStack: 'FlatStack',
  Gift: 'Gift',
  Globe: 'Globe',
  Hourglass: 'Hourglass',
  LinkedIn: 'LinkedIn',
  Picture: 'Picture',
  Reddit: 'Reddit',
  Telegram: 'Telegram',
  Triangle: 'Triangle',
  Twitter: 'Twitter',
  Wallet: 'Wallet',
}

export type IconName = typeof IconNames[keyof typeof IconNames];

export const Icons: Record<IconName, any> = {
  [IconNames.ArrowDown]: ArrowDown,
  [IconNames.ArrowLeft]: ArrowLeft,
  [IconNames.ArrowRight]: ArrowRight,
  [IconNames.ArrowUp]: ArrowUp,
  [IconNames.ArrowUpRight]: ArrowUpRight,
  [IconNames.Book]: Book,
  [IconNames.Bookmark]: Bookmark,
  [IconNames.Burger]: Burger,
  [IconNames.Compass]: Compass,
  [IconNames.DappRadar]: DappRadar,
  [IconNames.Discord]: Discord,
  [IconNames.Exit]: Exit,
  [IconNames.FlatStack]: FlatStack,
  [IconNames.Gift]: Gift,
  [IconNames.Globe]: Globe,
  [IconNames.Hourglass]: Hourglass,
  [IconNames.LinkedIn]: LinkedIn,
  [IconNames.Picture]: Picture,
  [IconNames.Reddit]: Reddit,
  [IconNames.Telegram]: Telegram,
  [IconNames.Triangle]: Triangle,
  [IconNames.Twitter]: Twitter,
  [IconNames.Wallet]: Wallet,
} as const;
