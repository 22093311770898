// extracted by mini-css-extract-plugin
export var body = "JQhSWbody";
export var column = "ohdbwcolumn";
export var header = "Wluzcheader";
export var icon = "qBbXEicon";
export var phantomIcon = "cSQ8zphantomIcon";
export var polkadotIcon = "za_LRpolkadotIcon";
export var root = "x1DBEroot";
export var subWalletIcon = "f4JEnsubWalletIcon";
export var talismanIcon = "s4yMjtalismanIcon";
export var title = "af5Extitle";