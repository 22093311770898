import * as React from 'react';

import DataFailed from './dataFailed.svg';
import * as styles from './styles.styl';

type Props = {
    error?: Error;
};

const ErrorPlaceholder = ({error}: Props) => {
    return (
      <div className={styles.root}>
          <DataFailed />
          <p>Oops! Something went wrong</p>
      </div>
    );
}

export default ErrorPlaceholder;
