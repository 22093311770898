import React from 'react';

import {describeWidget} from '@fidi/concrete/widgets';
import type {NotificationCollection, NotificationId} from '@fidi/concrete/entities/notification';

import api from '@/api';

import getInitialState from './getInitialState';
import view from './view';
import reducer from './reducer';
import * as epics from './epics';

export type Data = {
  notificationIds: NotificationId[],
};

export type Collections = NotificationCollection;

export type State = {
  collections: Collections;
};

const Notifications = describeWidget<{}, Data, Collections>({
  name: 'Notifications',
  getInitialState,
  view,
  reducer,
  api,
  placeholder: ({children}) => <>{children}</>,
  epics: Object.values(epics),
});

export default Notifications;
