import axios from 'axios';
import createApi from '@fidi/concrete/api';
import {getCookie} from '@/utils/cookie';

export type {Api} from '@fidi/concrete/api';

const BASE = process.env.BASE_URL ?? '';

const skHeader = ['k', 's', '-', 'x'].reverse().join('');

const getHeaders = () => {
  const result: Record<string, string> = {};

  // @ts-ignore
  if (window?.ethereum?.selectedAddress) {
    // @ts-ignore
    result['x-user-wallet'] = window.ethereum.selectedAddress.toLowerCase();
  }

  const date = new Date();
  result[skHeader] = `${Math.floor(Math.random()*Date.now()).toString().padEnd(15, '0')}${date.getTime().toString().padStart(15, '0').split("").reverse().join("")}${Math.floor(Math.random()*Date.now())}`;

  return result;
};

type CacheProps = {
  ttlMs: number;
};
const cache = <P, D>(func: (p: P) => Promise<D>, {ttlMs}: CacheProps) => {
  let cached: Record<string, Promise<D>> = {};

  return async (params: P) => {
    const hash = JSON.stringify(params);

    if (!cached[hash]) {
      cached[hash] = func(params)
        .then((data) => {
          setTimeout(() => {
            cached[hash] = null;
          }, ttlMs);
          return data;
        });
    }

    return cached[hash];
  };
};

let idPromise: Promise<any> | void = null;
const _request = async ({
  method = 'GET',
  params,
  data,
  url: _url,
}: {
  method?: 'GET' | 'POST' | 'DELETE' | 'PUT';
  params?: Record<string, any>;
  data?: any;
  url: string;
}) => {
  const url = _url[0] === '/'
    ? `${BASE}${_url}`
    : `${BASE}/${_url}`;
  const id = getCookie('id');
  if (id) {
    const {data: result} = await axios({
      method,
      params,
      data,
      url,
      withCredentials: true,
      headers: getHeaders(),
    });
    return result;
  }

  if (idPromise) {
    await idPromise;
    const {data: result} = await axios({
      method,
      params,
      data,
      url,
      withCredentials: true,
      headers: getHeaders(),
    });
    return result;
  } else {
    const request = axios({
      method,
      params,
      data,
      url,
      withCredentials: true,
      headers: getHeaders(),
    });
    idPromise = request;
    const {data: result} = await request;
    return result;
  }
};

const api = createApi({
  request: cache(_request, {ttlMs: 100}),
});

export const {
  /**
   * @deprecated
   */
  getStackWalletContent,
  /**
   * @deprecated
   */
  getStackProtocols,
  /**
   * @deprecated
   */
  getStackProtocol,
  /**
   * @deprecated
   */
  getStackAllocation,
  /**
   * @deprecated
   */
  getStackTotalBalance,
  /**
   * @deprecated
   */
  getStacks,
  /**
   * @deprecated
   */
  createStack,
  /**
   * @deprecated
   */
  deleteStack,
  /**
   * @deprecated
   */
  getStackHistory,
  /**
   * @deprecated
   */
  getStackNetWorthGraph,
  /**
   * @deprecated
   */
  saveStack,

  /**
   * @deprecated
   */
  getWalletContent,
  /**
   * @deprecated
   */
  getProtocols,
  /**
   * @deprecated
   */
  getProtocol,
  /**
   * @deprecated
   */
  getAllocation,
  /**
   * @deprecated
   */
  getTotalBalance,
  /**
   * @deprecated
   */
  getHistory,
  /**
   * @deprecated
   */
  getWalletNetWorthGraph,
  getRecentWallets,
  saveHiddenAssets,
  getWidgetData,
  getGiveaways,
} = api;

export default api;
