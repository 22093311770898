export const LIST_TYPES = {
  LIQUIDITY_POOL: 'LIQUIDITY_POOL',
  LENDING: 'LENDING',
  REWARDS: 'REWARDS',
  STAKED: 'STAKED',
  YIELD: 'YIELD',
  FARMING: 'FARMING',
  SAVINGS: 'SAVINGS',
  WALLET: 'WALLET',
  LOCKED: 'LOCKED',
  VESTING: 'VESTING',
  INSURANCE: 'INSURANCE',
  OTHER: 'OTHER',
} as const;

export type ListType = typeof LIST_TYPES[keyof typeof LIST_TYPES];

export const LIST_TYPES_NAME = {
  [LIST_TYPES.LIQUIDITY_POOL]: 'Liquidity Pool',
  [LIST_TYPES.LENDING]: 'Lending',
  [LIST_TYPES.REWARDS]: 'Rewards',
  [LIST_TYPES.STAKED]: 'Staked',
  [LIST_TYPES.YIELD]: 'Yield',
  [LIST_TYPES.FARMING]: 'Farming',
  [LIST_TYPES.SAVINGS]: 'Savings',
  [LIST_TYPES.WALLET]: 'Wallet',
  [LIST_TYPES.LOCKED]: 'Locked',
  [LIST_TYPES.VESTING]: 'Vesting',
  [LIST_TYPES.INSURANCE]: 'Insurance Buyer',
  [LIST_TYPES.OTHER]: 'Other',
};

