import {configureStore, createReducer} from '@reduxjs/toolkit';

import {updateCollections, updateHeap} from './actions';
import {middleware} from './widgets';
import {Mode} from './constants/mode';
import {WalletId} from './entities/wallet';
import {uniq} from './helpers/arrays';
import {assignDefined} from './helpers/objects';

export type Heap = {
  walletId?: string;
  externalWalletId?: string;
  mode: Mode;
  recentWallets: WalletId[];
  swap: {
    isVisible: boolean;
    fromChain?: number;
    fromToken?: string;
  };
};

export default (initialHeap: Heap) => {
  return configureStore({
    reducer: {
      heap: createReducer(initialHeap, builder =>
        builder.addCase(updateHeap, (heap, {payload}: {payload: Heap}) => ({
          ...heap,
          ...payload,
        }))
      ),
      collections: createReducer({}, builder =>
        builder.addCase(updateCollections, (collections, {payload}) => {
          for (const [key, value] of Object.entries(payload)) {
            const oldEntities = collections[key] || {};
            const newEntities = value || {};
            const ids = uniq([...Object.keys(oldEntities), ...Object.keys(newEntities)]);

            collections[key] = ids.reduce((acc, id) => {
              acc[id] = assignDefined({}, oldEntities[id] || {}, newEntities[id] || {});
              return acc;
            }, {});
          }
        })
      )
    },
    middleware: [middleware],
  });
};
