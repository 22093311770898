import {createContext} from 'react';

export const THEMES = {
  LIGHT: 'light',
  DARK: 'dark',
} as const;

export type Theme = typeof THEMES[keyof typeof THEMES];

export const getOppositeTheme = (theme: Theme) => {
  if (theme === THEMES.DARK) {
    return THEMES.LIGHT;
  }
  return THEMES.DARK;
};

export const ThemeContext = createContext({
  theme: THEMES.LIGHT,
  toggleTheme: () => {},
});
