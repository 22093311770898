import { schema } from 'normalizr';

export const chain = new schema.Entity('chain', {
}, {
  processStrategy: value => ({
    id: value.id,
    name: value.name,
    chainId: value.chainId,
    logoUrl: value.logoUrl,
  }),
});
