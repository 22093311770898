import React from 'react';

import Up from '@/uikit/icons/up.svg';

import * as styles from './styles.styl';

const LINKS = [
  {href: 'https://dot.fidi.tech', label: 'Code-Free Analytics'},
  {href: '/', label: 'Portfolio Tracker'},
];

const Link = ({href, label}: {href: string, label: string}) =>
  <a href={href} className={styles.link}>
    <div className={styles.linkContainer}>
      <div className={styles.linkLabel}>{label}</div>
      <div className={styles.linkArrow}><Up /></div>
    </div>
  </a>

const SideMenu = () => {
  return (
    <div className={styles.root}>
      {LINKS.map(props => <Link {...props} />)}
    </div>
  )
}

export default SideMenu;