import React from 'react';
import cx from 'classnames';

import * as styles from './styles.styl';

const Links = () => {
  return (
    <div className={styles.root}>
      <a href="https://dot.fidi.tech" className={styles.link}>Code&#8209;Free Analytics</a>
      <a href="/" className={cx(styles.link, styles.active)}>Portfolio Tracker</a>
    </div>
  );
}

export default Links;