import React, {useEffect} from 'react';

import Loader from '@/uikit/components/Loader';

import * as styles from './styles.styl';

const RedirectUrl = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <div className={styles.root}>
    <Loader />
  </div>;
};

export default RedirectUrl;