import {createSelector} from '@reduxjs/toolkit';

import {NotificationCollection, NotificationId} from '.';

type State = {
  collections: NotificationCollection
};

export const selectNotifications = (state: State) => state.collections.notification;
export const selectNotificationById = (state: State, id: NotificationId) => selectNotifications(state)[id];
export const selectNotificationsByIds = createSelector(
  selectNotifications,
  (_, ids) => ids,
  (notifications, ids) => ids.map(id => notifications[id])
)
