export const getRedirectLocation = (location, walletId) => {
  const {pathname, search} = location;
  if (pathname.includes('portfolio')) {
    if (!search.includes(`wallet_id=${walletId}`) || search.includes('stack_id')) {
      return `/portfolio${walletId ? `?wallet_id=${walletId}` : ''}`;
    }
  }
  if (pathname.includes('history')) {
    if (!search.includes(`wallet_id=${walletId}`) || search.includes('stack_id')) {
      return `/history${walletId ? `?wallet_id=${walletId}` : ''}`;
    }
  }
}