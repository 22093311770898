import * as React from 'react';
import {useEffect, useMemo, useRef} from 'react';
import {useStore, Provider} from 'react-redux';
import {EMPTY, Subject} from 'rxjs';
import {catchError, filter, mergeMap} from 'rxjs/operators'
import {createReducer, createAction} from '@reduxjs/toolkit';
import {ActionReducerMapBuilder} from '@reduxjs/toolkit/src/mapBuilders';
import {combineEpics, Epic, createEpicMiddleware} from 'redux-observable';
import {ErrorBoundary, startTransaction} from '@sentry/react';

import {updateCollections, updateWidgetsGroup} from '../actions';
import {Api} from '../api';
import {useComplexEffect} from '../helpers/useComplexEffect';

export const setInitialData = createAction('@widget/setInitialData');

export type GetInitialState<P, D, C> = (ctx: {api: Api}, props: P) => Promise<{
  data: D;
  collections: C;
} | null>;

export type WidgetReducer<D> = (builder: ActionReducerMapBuilder<D>) => void;
export type WidgetGroupId = string;

type WidgetDescription<P, D, C> = {
  name: string;
  getInitialState: GetInitialState<P, D, C>;
  view: React.ComponentType<D>;
  reducer: WidgetReducer<D>;
  epics?: Epic[];
  placeholder: React.ComponentType<{children?: React.ReactNode;}>;
  api: Api;
  errorPlaceholder?: React.ComponentType<{error?: Error}>;
};

type WidgetOptions = {
  onLoadEnd?: () => void;
  groupIds?: WidgetGroupId[];
};

// @ts-ignore
const widgetsMiddlewares = [];

export const middleware = () => (next: (arg0: any) => void) => (action: any) => {
  // @ts-ignore
  for (const epicMiddleware of widgetsMiddlewares) {
    epicMiddleware(action);
  }
  next(action);
};

const groupUpdateEpic = (groupIds, callback) => (api, data, action$) =>
  action$.pipe(
    filter(updateWidgetsGroup.match),
    mergeMap(({payload}) => {
      if (groupIds.includes(payload)) {
        callback();
      }
      return EMPTY;
    })
  );

// @ts-ignore
export const describeWidget = <P, D, C>(config: WidgetDescription<P, D, C>): React.ComponentType<P & WidgetOptions> => React.memo((props: P & WidgetOptions) => {
  const store = useStore();

  const isMountedRef = React.useRef(true);
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  useComplexEffect(() => setError(null), [], [props]);
  const [data, dataDispatch] = React.useReducer(createReducer(null, builder => {
    config.reducer(builder.addCase(setInitialData, (data, {payload}) => payload));
  }), null);
  const data$ = React.useMemo(() =>
      new Subject(),
      []
  );
  React.useEffect(() => {
    data$.next(data);
  }, [data]);
  const resultDispatch = React.useCallback(action => {
    dataDispatch(action);
    store.dispatch(action);
  }, [dataDispatch]);
  const newStore = useMemo(() => ({...store, dispatch: resultDispatch}), []);

  const loadRef = useRef<null | (() => void)>(null);
  useComplexEffect(() => {
    loadRef.current = () => {
      setIsLoading(true);
      setError(false);
      const initialStateTransaction = startTransaction({
        name: `widget-${config.name}`,
      });
      const initialStateSpan = initialStateTransaction.startChild({
        data: {
          props,
        },
        op: 'getInitialState',
      });
      const initialStatePromise = config.getInitialState({api: config.api}, props);
      return initialStatePromise.then((resolved) => {
        if (props === propsRef.current && isMountedRef.current) {
          if (!resolved) {
            initialStateSpan.setStatus('resolved-null');
            initialStateSpan.finish();
            initialStateTransaction.finish();
            setError(true);
            return;
          }
          const {data: initialData, collections: initialCollections} = resolved;
          // @ts-ignore
          store.dispatch(updateCollections(initialCollections));
          // @ts-ignore
          dataDispatch(setInitialData(initialData));
          setIsLoading(false);
          if (props.onLoadEnd) {
            props.onLoadEnd();
          }
          initialStateSpan.setStatus('ok');
          initialStateSpan.finish();
          initialStateTransaction.finish();
        }
      }).catch(err => {
        initialStateSpan.setStatus('error');
        initialStateSpan.finish();
        initialStateTransaction.finish();
        setError(err);
      })
    };
  }, [setIsLoading, dataDispatch, setError, config], [props]);

  useEffect(() => {
    if (config.epics || props.groupIds) {
      const epicMiddleware = createEpicMiddleware();
      // @ts-ignore
      const initialEpicMiddleware = epicMiddleware(newStore)(() => {});
      widgetsMiddlewares.push(initialEpicMiddleware);
      epicMiddleware.run(combineEpics(
        ...(config.epics
          ? config.epics.map(e =>
            (action$, state$) =>
              // @ts-ignore
              e(config.api, data$, action$, state$)
                .pipe(catchError(err => {
                  console.error(err);
                  setError(err);
                  return EMPTY;
                }))
          )
          : []),
        ...(props.groupIds ? [groupUpdateEpic(props.groupIds, loadRef.current).bind(this, config.api, data$)] : []),
      ));
      return () => {
        const index = widgetsMiddlewares.indexOf(initialEpicMiddleware);
        if (index >= 0) {
          widgetsMiddlewares.splice(index, 1);
        }
      }
    }
  }, [props.groupIds, newStore, config.epics, data$]);

  const propsRef = React.useRef(props);
  useComplexEffect(() => {
    propsRef.current = props;
  }, [], [props]);

  useComplexEffect(() => {
    loadRef.current();
  }, [], [props]);

  if (error) {
    if (typeof error !== 'boolean') {
      const ErrorPlaceholder = config.errorPlaceholder;
      const fallback = ErrorPlaceholder ? <ErrorPlaceholder error={error instanceof Error ? error : undefined}/> : null;
      const ErrorSimulator = () => {
        throw error;
      };
      return (
        <ErrorBoundary fallback={fallback}>
          <ErrorSimulator/>
        </ErrorBoundary>
      );
    }
    return null;
  }

  const Placeholder = config.placeholder;
  const ErrorPlaceholder = config.errorPlaceholder;
  const View = config.view;
  return (
    /* @ts-ignore */
    <Provider store={newStore}>
      <ErrorBoundary fallback={ErrorPlaceholder ? <ErrorPlaceholder /> : null} >
        {isLoading && <Placeholder />}
        {!isLoading && <Placeholder><View {...data} /></Placeholder>}
      </ErrorBoundary>
    </Provider>
  );
});
