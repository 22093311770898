import {schema} from 'normalizr';

import {COLUMNS} from '../../tableData/schemas/appStaking';

export const tableDataRow = new schema.Entity('tableDataRow', {}, {
  idAttribute: (value, parent) => `${parent.id}-row-${value.id}`,
  processStrategy: (value, parent) => {
    return {
      id: `${parent.id}-row-${value.id}`,
      columns: [COLUMNS.NAME, COLUMNS.TVL, COLUMNS.UNBONDING, COLUMNS.BALANCE, COLUMNS.VALUE].map(column => column.id),
      values: [
        {
          id: value.dappRadarId,
          text: `${value.name || value.id}`,
          link: value.dappRadarLink,
        },
        {
          text: value.tvl,
        },
        {
          startedAt: value.unbonding?.startedAt,
          finishesAt: value.unbonding?.finishesAt,
        },
        {
          text: value.balance,
        },
        {
          text: value.value,
        },
      ]
    }
  },
});