import {ApiConfig} from './index';

export type WidgetId = string;

type Props = {
  widgetId: WidgetId;
} & Record<string, string | number | string[] | number[]>;

export const getWidgetData = (config: ApiConfig) => ({widgetId, ...rest}: Props) =>
  config.request({
    method: 'GET',
    url: `/api/widget`,
    params: {
      widgetId,
      ...rest,
    }
  });