import { schema } from 'normalizr';

import {USD_ID} from '@fidi/concrete/entities/currency/constants';

export const coin = new schema.Entity('coin', {}, {
  processStrategy: value => ({
    externalLogoUrl: value.logoUrl,
    ticker: value.symbol,
    ...value,
    price: value.price ? {[USD_ID]: value.price} : null,
  }),
});
