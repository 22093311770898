import {WidgetReducer} from '@fidi/concrete/widgets';

import {Data} from '.';
import {hideNotification, showNotification} from './actions';

const reducer: WidgetReducer<Data> = (builder =>
  builder.addCase(showNotification, (data, {payload: notificationId}) => {
    data.notificationIds = [...data.notificationIds, notificationId];
  }).addCase(hideNotification, (data, {payload: notificationId}) => {
    data.notificationIds = data.notificationIds.filter(id => id !== notificationId);
  })
);

export default reducer;
