import '@/uikit/global.styl';
import '@/uikit/reset.styl';

import * as React from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';

import RedirectUrl from '@/components/RedirectUrl';

import Layout from './layout';

const Root = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate('/portfolio');
  },[]);
  return <></>;
};

const App = () => {
  return (
    <Routes>
      <Route path="/portfolio" element={<Layout>
        <React.Suspense fallback={null}>
          {React.createElement(React.lazy(() => import('@/pages/Dashboard')))}
        </React.Suspense>
      </Layout>} />
      <Route path="/history" element={<Layout>
        <React.Suspense fallback={null}>
          {React.createElement(React.lazy(() => import('@/pages/History')))}
        </React.Suspense>
      </Layout>} />
      <Route path="/dapp" element={<Layout>
        <React.Suspense fallback={null}>
          {React.createElement(React.lazy(() => import('@/pages/DApp')))}
        </React.Suspense>
      </Layout>} />
      <Route path="/request-access" element={
        <React.Suspense fallback={null}>
          {React.createElement(React.lazy(() => import('@/pages/RequestAccess')))}
        </React.Suspense>
      }/>
      <Route path="/giveaway/:giveawayId" element={<Layout>
        <React.Suspense fallback={null}>
          {React.createElement(React.lazy(() => import('@/pages/Giveaway')))}
        </React.Suspense>
      </Layout>} />
      <Route path="/" element={<Layout><Root /></Layout>} />
      <Route path="*" element={<RedirectUrl url="https://fidi.tech" />} />
    </Routes>
  );
};

export default App;
