import { schema } from 'normalizr';

import {nftCollection} from '../nftCollection/schema';

export const nft = new schema.Entity('nft', {
  nftCollectionId: nftCollection
}, {
  idAttribute: value => nftId(value),
  processStrategy: value => ({
    id: nftId(value),
    contentType: value.content_type,
    amount: value.amount,
    picture: value.content,
    thumbnail: value.thumbnail_url,
    detailUrl: value.detail_url,
    innerId: value.inner_id,
    name: value.name,
    lastPrice: value.pay_token?.amount,
    nftCollectionId: getNFTCollection(value),
  }),
});

const nftId = value => `debank${value.id}`;

const getNFTCollection = ({
  chain,
  collection_id,
  contract_id,
  contract_name,
  description,
}) => ({
  chain,
  collection_id,
  contract_id,
  contract_name,
  description,
})