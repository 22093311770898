import {
  createStack, deleteStack,
  getStackAllocation, getStackHistory, getStackNetWorthGraph,
  getStackProtocols, getStacks,
  getStackTotalBalance,
  getStackTotalRewards,
  getStackWalletContent, saveStack,
  getStackHistoryByFilter,
  getStackCoins,
  getStackChains,
  getStackNFTs,
} from './stacks';
import {
  getAllocation,
  getHistory,
  getProtocols,
  getTotalBalance,
  getTotalRewards,
  getWalletContent,
  getWalletNetWorthGraph,
  getHistoryByFilter,
  getWalletChains,
  getWalletCoins,
  getRecentWallets,
  getWalletNFTs,
  clearWalletCache,
} from './wallet';
import {
  saveHiddenAssets,
} from './assets';
import {
  getWidgetData,
} from './widget';
import {
  enrollInGateway,
  getGiveaways,
  claim,
} from './giveaway';

type RequestConfig = {
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  data?: any;
  params?: Record<string, any>;
  url: string;
};

export type ApiConfig = {
  request: (config: RequestConfig) => Promise<any>;
};

const createApi = (config: ApiConfig) => {
  return {
    getStackWalletContent: getStackWalletContent(config),
    getStackProtocols: getStackProtocols(config),
    getStackAllocation: getStackAllocation(config),
    getStackTotalBalance: getStackTotalBalance(config),
    getStackTotalRewards: getStackTotalRewards(config),
    getStacks: getStacks(config),
    createStack: createStack(config),
    deleteStack: deleteStack(config),
    /**
     * @deprecated
     */
    getStackHistory: getStackHistory(config),
    getStackNetWorthGraph: getStackNetWorthGraph(config),
    saveStack: saveStack(config),
    getStackHistoryByFilter: getStackHistoryByFilter(config),
    getStackCoins: getStackCoins(config),
    getStackChains: getStackChains(config),
    getStackNFTs: getStackNFTs(config),

    getWalletContent: getWalletContent(config),
    getProtocols: getProtocols(config),
    getAllocation: getAllocation(config),
    getTotalBalance: getTotalBalance(config),
    getTotalRewards: getTotalRewards(config),
    /**
     * @deprecated
     */
    getHistory: getHistory(config),
    getWalletNetWorthGraph: getWalletNetWorthGraph(config),
    getHistoryByFilter: getHistoryByFilter(config),
    getWalletChains: getWalletChains(config),
    getWalletCoins: getWalletCoins(config),
    getRecentWallets: getRecentWallets(config),
    getWalletNFTs: getWalletNFTs(config),
    saveHiddenAssets: saveHiddenAssets(config),
    getWidgetData: getWidgetData(config),
    clearWalletCache: clearWalletCache(config),

    getGiveaways: getGiveaways(config),
    enrollInGateway: enrollInGateway(config),
    claim: claim(config),
  };
};

export type Api = ReturnType<typeof createApi>;

export default createApi;
