import React, {SyntheticEvent} from 'react';

import Cross from '@/uikit/icons/cross.svg';

import * as styles from './styles.styl';

type HeaderProps = {
  title: string;
  icon?: React.ReactNode;
  onClose?: (e: SyntheticEvent) => void;
}

const Header = ({title, icon, onClose}: HeaderProps) => (
  <div className={styles.root}>
    {icon &&
      <div className={styles.icon}>
        {icon}
      </div>
    }
    <p className={styles.title}>{title}</p>
    {onClose &&
      <div className={styles.close} onClick={onClose}>
        <Cross />
      </div>
    }
  </div>
)

export default Header;