import {normalize} from 'normalizr';
import {ApiConfig} from './index';
import {WalletCollection, WalletId} from '../entities/wallet';
import {AssetList, AssetListCollection, AssetListId} from '../entities/assetList';
import {AssetListItemCollection} from '../entities/assetListItem';
import {AssetCollection} from '../entities/asset';
import {CoinCollection} from '../entities/coin';
import {assetList} from '../entities/assetList/schema';
import {ProtocolCollection, ProtocolId} from '../entities/protocol';
import {protocol} from '../entities/protocol/schema';
import {LIST_TYPES, ListType} from '../entities/assetList/constants';
import {assetListItemPair} from '../entities/assetListItem/schema/assetListItemPair';
import {AllocationCollection, AllocationId} from '../entities/allocation';
import {ChainCollection, ChainId} from '../entities/chain';
import {allocation, protocolAllocation, tokenAllocation} from '../entities/allocation/schema';
import {HistoryItemCollection, HistoryItemId} from '../entities/historyItem';
import {normalizeHistoryItems} from '../entities/historyItem/schema';
import {TimeSeriesCollection, TimeSeriesId} from '../entities/timeSeries';
import {timeSeries} from '../entities/timeSeries/schema';
import {CurrencyCollection} from '../entities/currency';
import {wallet} from '../entities/wallet/schema';
import {nft} from '../entities/nft/schema';
import {NFTCollection, NFTId} from '../entities/nft';
import {NFTCollectionCollection} from '../entities/nftCollection';
import hash from 'object-hash';

export const getWalletContent = (config: ApiConfig) => async (walletId: WalletId): Promise<{
  result: AssetListId;
  entities:
    & AssetListCollection
    & AssetListItemCollection
    & AssetCollection
    & CoinCollection;
}> => {
  const data = await config.request({
    url: `/api/wallets/${walletId}/tokens`,
  });
  return normalize(
    data
      .filter(({amount}: {amount: number}) => amount > 0),
    assetList,
  );
};

const TYPE_MAP: Record<string, ListType> = {
  'Liquidity Pool': LIST_TYPES.LIQUIDITY_POOL,
  'Lending': LIST_TYPES.LENDING,
  'Rewards': LIST_TYPES.REWARDS,
  'Staked': LIST_TYPES.STAKED,
  'Yield': LIST_TYPES.YIELD,
  'Farming': LIST_TYPES.FARMING,
  'Savings': LIST_TYPES.SAVINGS,
  'Wallet': LIST_TYPES.WALLET,
  'Locked': LIST_TYPES.LOCKED,
  'Vesting': LIST_TYPES.VESTING,
  'Insurance Buyer': LIST_TYPES.INSURANCE,
};
export const getProtocols = (config: ApiConfig) => async (walletId: WalletId): Promise<{
  result: Record<ProtocolId, Record<ListType, AssetListId>>;
  entities: ProtocolCollection
    & AssetListCollection
    & AssetListItemCollection
    & AssetCollection
    & CoinCollection
    & AllocationCollection;
}> => {
  const allData = await config.request({
    url: `/api/wallets/${walletId}/protocols`,
  });
  const result: Record<ProtocolId, Record<ListType, AssetListId>> = {};
  const entities: Record<string, Record<string, any>> = {
    ...normalize(allData, [protocol]).entities,
    assetList: {},
  };

  for (const data of allData) {
    // @ts-ignore
    const mapping: Record<ListType, any[]> = {};
    for (const piece of data.portfolio_item_list) {
      const type = TYPE_MAP[piece.name] || LIST_TYPES.OTHER;
      if (!mapping[type]) {
        mapping[type] = [];
      }
      const tokens = (piece.detail.supply_token_list || piece.detail.token_list || [])
        .concat((piece.detail.borrow_token_list || []).map(t => ({...t, amount: -t.amount})))
        .map((t: any) => ({
          ...t,
          id: `${walletId}-${data.id}-${type}-${t.id}-${t.amount}`,
        }));
      if (piece.detail.reward_token_list) {
        tokens.rewards = piece.detail.reward_token_list;
      }
      if (piece.additional) {
        tokens.additional = piece.additional;
      }
      mapping[type].push(normalize(tokens, assetListItemPair));
    }

    // @ts-ignore
    result[data.id] = {};
    for (const [type, items] of Object.entries(mapping)) {
      const assetList: AssetList = {
        id: '',
        items: [],
      };

      for (const {result, entities: _entities} of items) {
        if (typeof result === 'string') {
          assetList.items.push(result);
        } else {
          for (const p of result) {
            assetList.items.push(p);
          }
        }
        for (const k of Object.keys(_entities)) {
          if (!entities[k]) {
            entities[k] = {};
          }
          Object.assign(entities[k], _entities[k]);
        }
      }

      assetList.id = hash(assetList);
      entities.assetList[assetList.id] = assetList;
      // @ts-ignore
      result[data.id][type] = assetList.id;
    }
  }

  // @ts-ignore
  return {result, entities};
};

export const getAllocation = (config: ApiConfig) => async (walletId: WalletId): Promise<{
  result: {
    chain: AllocationId;
    coin: AllocationId;
    protocol: AllocationId;
  };
  entities:
    & ChainCollection
    & AllocationCollection
    & AssetCollection
    & CoinCollection
    & ProtocolCollection;
}> => {
  const data = await config.request({
    url: `/api/wallets/${walletId}/allocations`,
  });
  const chain = data.chains.length > 0 ? normalize({...data, id: Math.random()}, allocation) : undefined;
  const coin = data.tokens.length > 0 ? normalize({...data, id: Math.random()}, tokenAllocation) : undefined;
  const protocol = data.protocols.length > 0 ? normalize({...data, id: Math.random()}, protocolAllocation) : undefined;

  const entities: ChainCollection & AllocationCollection & AssetCollection & CoinCollection & ProtocolCollection = {
    chain: {},
    asset: {},
    coin: {},
    allocation: {},
    protocol: {},
  };
  for (const normalized of [chain, coin, protocol]) {
    if (normalized) {
      const {entities: _e} = normalized;
      Object.assign(entities.chain, _e.chain);
      Object.assign(entities.asset, _e.asset);
      Object.assign(entities.coin, _e.coin);
      Object.assign(entities.allocation, _e.allocation);
      Object.assign(entities.protocol, _e.protocol);
    }
  }

  return {
    result: {
      chain: chain?.result,
      coin: coin?.result,
      protocol: protocol?.result,
    },
    entities,
  };
};

export const getTotalBalance = (config: ApiConfig) => async (walletId: WalletId): Promise<{
  result: number;
}> => {
  const data = await config.request({
    url: `/api/wallets/${walletId}/allocations`,
  });
  return {
    result: data.total_usd_value,
  };
};
export const getTotalRewards = (config: ApiConfig) => async (walletId: WalletId): Promise<{
  timeAt: number,
  value: number,
}[]> => config.request({
    url: `/api/wallets/${walletId}/rewards`,
  });

export const getHistory = (config: ApiConfig) => async ({walletId, startTime = new Date(0).toISOString(), pageCount = 100}: {walletId: string, startTime?: string, pageCount?: number}): Promise<{
  result: {
    items: HistoryItemId[],
    hasMore: boolean,
  },
  entities:
    & HistoryItemCollection
    & CoinCollection
}> => {
  const data = await config.request({
    url: `/api/wallets/${walletId}/history`,
    params: {
      start_time: startTime,
      page_count: pageCount,
    },
  });
  const {result, entities} = normalizeHistoryItems(data);
  return {
    result: {
      items: result,
      hasMore: result.length >= pageCount,
    },
    entities,
  };
};

export const getHistoryByFilter = (config: ApiConfig) => async ({walletId, from, to}: {walletId: string, from: string, to: string}): Promise<{
  result: {
    items: HistoryItemId[],
  },
  entities:
    & HistoryItemCollection
    & CoinCollection
}> => {
  const data = await config.request({
    url: `/api/wallets/${walletId}/history/v2`,
    params: {
      from,
      to,
    },
  });
  const {result, entities} = normalizeHistoryItems(data);
  return {
    result: {
      items: result,
    },
    entities,
  };
};

export const getWalletNetWorthGraph = (config: ApiConfig) => async ({walletId, chartsType}: {walletId: string, chartsType: string}): Promise<{
  result: TimeSeriesId;
  entities: TimeSeriesCollection;
}> => {
  const data = await config.request({
    url: `/api/wallets/${walletId}/allocations/net_worth`,
    params: {
      charts_type: chartsType,
    },
  });

  if (!data) {
    return {
      result: null,
      entities: {
        timeSeries: {},
      },
    };
  }

  return normalize({id: Math.random().toString(), data}, timeSeries);
};

export const getWalletChains = (config: ApiConfig) => async ({walletId}: {walletId: string}): Promise<{
  result: Record<ChainId, Record<ListType, AssetListId>>;
  entities: AssetListCollection
    & AssetListItemCollection
    & AssetCollection
    & CurrencyCollection
    & ProtocolCollection
    & CoinCollection
    & ChainCollection;
}> => {
  const chainsData = await config.request({url: `/api/wallets/${walletId}/chains`});

  const chainResult: Record<ChainId, Record<ListType, AssetListId>> = {};
  const entities: Record<string, Record<string, any>> = {
    assetList: {},
    chain: {},
  };

  for (const data of chainsData) {
    entities.chain[data.chain] = {
      id: data.chain,
      name: data.name,
      logoUrl: data.logo_url,
      chainId: data.chainId,
    };

    // @ts-ignore
    const mapping: Record<ListType, any[]> = {};
    for (const piece of data.portfolio_item_list) {
      const type = TYPE_MAP[piece.name] || LIST_TYPES.OTHER;
      if (!mapping[type]) {
        mapping[type] = [];
      }
      const tokens = (piece.detail.supply_token_list || piece.detail.token_list || [])
        .concat((piece.detail.borrow_token_list || []).map(t => ({...t, amount: -t.amount})))
        .map((t: any) => ({
          ...t,
          id: `${walletId}-${data.id}-${type}-${t.id}-${t.amount}`,
        }));
      if (piece.detail.reward_token_list) {
        tokens.rewards = piece.detail.reward_token_list;
      }
      if (piece.additional) {
        tokens.additional = piece.additional;
      }
      mapping[type].push(normalize(tokens, assetListItemPair));
    }

    // @ts-ignore
    const result: Record<ListType, AssetListId> = {};
    for (const [type, items] of Object.entries(mapping)) {
      const assetList: AssetList = {
        id: '',
        items: [],
      };

      for (const {result, entities: _entities} of items) {
        if (typeof result === 'string') {
          assetList.items.push(result);
        } else {
          for (const p of result) {
            assetList.items.push(p);
          }
        }
        for (const k of Object.keys(_entities)) {
          if (!entities[k]) {
            entities[k] = {};
          }
          Object.assign(entities[k], _entities[k]);
        }
      }

      assetList.id = hash(assetList);
      entities.assetList[assetList.id] = assetList;
      // @ts-ignore
      result[type] = assetList.id;
    }

    chainResult[data.chain] = result;
  }

  // @ts-ignore
  return {result: chainResult, entities};
}

export const getWalletCoins = (config: ApiConfig) => async ({walletId}: {walletId: string}): Promise<{
  result: Record<ListType, AssetListId>;
  entities: AssetListCollection
    & AssetListItemCollection
    & AssetCollection
    & CurrencyCollection
    & ProtocolCollection
    & CoinCollection
    & ChainCollection;
}> => {
  const data = await config.request({url: `/api/wallets/${walletId}/coins`});

  const entities: Record<string, Record<string, any>> = {
    assetList: {},
  };

  // @ts-ignore
  const mapping: Record<ListType, any[]> = {};
  for (const piece of data.portfolio_item_list) {
    const type = TYPE_MAP[piece.name] || LIST_TYPES.OTHER;
    if (!mapping[type]) {
      mapping[type] = [];
    }
    const tokens = (piece.detail.supply_token_list || piece.detail.token_list || [])
      .concat((piece.detail.borrow_token_list || []).map(t => ({...t, amount: -t.amount})))
      .map((t: any) => ({
        ...t,
        id: `${walletId}-${data.id}-${type}-${t.id}-${t.amount}`,
      }));
    if (piece.detail.reward_token_list) {
      tokens.rewards = piece.detail.reward_token_list;
    }
    if (piece.additional) {
      tokens.additional = piece.additional;
    }
    mapping[type].push(normalize(tokens, assetListItemPair));
  }

  // @ts-ignore
  const result: Record<ListType, AssetListId> = {};
  for (const [type, items] of Object.entries(mapping)) {
    const assetList: AssetList = {
      id: '',
      items: [],
    };

    for (const {result, entities: _entities} of items) {
      if (typeof result === 'string') {
        assetList.items.push(result);
      } else {
        for (const p of result) {
          assetList.items.push(p);
        }
      }
      for (const k of Object.keys(_entities)) {
        if (!entities[k]) {
          entities[k] = {};
        }
        Object.assign(entities[k], _entities[k]);
      }
    }

    assetList.id = hash(assetList);
    entities.assetList[assetList.id] = assetList;
    // @ts-ignore
    result[type] = assetList.id;
  }

  // @ts-ignore
  return {result, entities};
}

export const getRecentWallets = (config: ApiConfig) => async ({query}: {query: string}): Promise<{
  result: WalletId[];
  entities: WalletCollection;
}> => {
  const {wallets} = await config.request({url: '/api/wallets/recent', params: {query}});
  return normalize(wallets.map(walletId => ({id: walletId, hash: walletId})), [wallet]);
}

export const getWalletNFTs = (config: ApiConfig) => async ({walletId}: {walletId: string}): Promise<{
  result: NFTId[],
  entities: NFTCollection & NFTCollectionCollection,
}> => {
  const data = await config.request({url: `/api/wallets/${walletId}/nfts`});
  return normalize(data, [nft]);
}

export const clearWalletCache = (config: ApiConfig) => async ({walletId}: {walletId: string}): Promise<{}> =>
  config.request({
    method: 'POST',
    url: `/api/wallets/${walletId}/clear-cache`,
  });