import React from 'react';

import * as styles from './styles.styl';

type Props = {
  name: string;
  description?: string;
  onClick: () => void;
  onDescriptionClick?: () => void;
  icon: React.ReactNode;
};

const Item = ({name, description, onClick, onDescriptionClick, icon}: Props) =>
  <div className={styles.root} onClick={onClick}>
    <div className={styles.icon}>
      {icon}
    </div>
    <div className={styles.container}>
      <div className={styles.title}>
        {name}
      </div>
      {description &&
      <div className={styles.description}>
            <span onClick={onDescriptionClick}>
              {description}
            </span>
      </div>
      }
    </div>
  </div>

export default Item;