import { schema } from 'normalizr';
import {coin} from '../coin/schema';
import {chain} from '../chain/schema';
import {protocol} from '../protocol/schema';

export const asset = new schema.Entity('asset', {
  coinId: coin,
}, {
  idAttribute: value => value.id,
  processStrategy: value => ({
    id: value.id,
    coinId: {
      id: `${value.chainId}-${value.id}`,
      name: value.name,
      ticker: value.symbol,
      price: value.price,
      priceSource: value.priceSource,
      externalLogoUrl: value.logoUrl,
      chainId: value.chain,
      chain: value.chain,
      address: value.address,
      isHidden: value.isHidden,
    },
  }),
});

export const assetChain = new schema.Entity('asset', {
  chainId: chain,
}, {
  idAttribute: value => `chain-${value.id}`,
  processStrategy: value => ({
    id: `chain-${value.id}`,
    chainId: {
      id: value.id,
      chainId: value.chainId,
      name: value.name,
      logoUrl: value.logo_url,
    },
  }),
});

export const assetProtocol = new schema.Entity('asset', {
  protocolId: protocol,
}, {
  idAttribute: value => `protocol-${value.id}`,
  processStrategy: value => ({
    id: `protocol-${value.id}`,
    protocolId: {
      id: value.id,
      name: value.name,
    },
  }),
});

export const namedAsset = new schema.Entity('asset', {}, {
  idAttribute: value => `named-${value.id}`,
  processStrategy: value => ({
    id: `named-${value.id}`,
    name: value.name,
  }),
});
