import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';

import {Heap} from '@fidi/concrete/store';
import DarkModeToggler from '@/components/DarkModeToggler';
import Button from '@/uikit/components/Button';
import Cross from '@/uikit/icons/cross.svg';
import Burger from '@/uikit/icons/burger.svg';

import Links from './Links';
import SideMenu from './SideMenu';
import * as styles from './styles.styl';

const LazyUserWallet = React.lazy(() => import('@/widgets/UserWalletCompact'));

const Header = () => {
    const walletId = useSelector((state: {heap: Heap}) => state.heap.walletId);
    const [isSideMenuActive, setIsSideMenuActive] = useState(false);
    const toggleSideMenu = useCallback(
      () => setIsSideMenuActive(!isSideMenuActive),
      [isSideMenuActive, setIsSideMenuActive],
    );
    return (
        <div className={styles.root}>
          <a href="https://fidi.tech" className={styles.badge}>
            <div className={styles.logo} />
          </a>
          <div className={styles.links}>
            <Links />
          </div>
          <div className={styles.right}>
              <DarkModeToggler />
              <React.Suspense fallback={null}>
                <LazyUserWallet walletId={walletId} isClickable />
              </React.Suspense>
          </div>
          <Button
            icon={isSideMenuActive ? <Cross /> : <Burger />}
            onClick={toggleSideMenu}
            className={styles.burger}
            theme="Minor"
          />
          {isSideMenuActive && (
            <div className={styles.sideMenuWrapper}>
              <SideMenu />
            </div>
          )}
        </div>
    );
}

export default Header;
