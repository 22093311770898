import React from 'react';

import {describeWidget} from '@fidi/concrete/widgets';
import api from '@/api';
import ErrorPlaceholder from '@/components/ErrorPlaceholder';

import getInitialState from './getInitialState';
import view from './view';
import reducer from './reducer';
import * as epics from './epics';
import Placeholder from './components/Placeholder'

export type Data = {
  connectingInProgress: boolean,
};

export type Collections = {};

export type State = {
  collections: Collections;
};

const ConnectionManager = describeWidget<{}, Data, Collections>({
  name: 'ConnectionManager',
  getInitialState,
  view,
  epics: Object.values(epics),
  reducer,
  placeholder: Placeholder,
  api,
  errorPlaceholder: ErrorPlaceholder,
});

export default ConnectionManager;
