import {schema} from 'normalizr';

import {tableDataColumn} from '../../tableDataColumn/schemas/default';
import {tableDataRow} from '../../tableDataRow/schemas/appStaking';

export const COLUMN_CODES = {
  NAME: 'dApp Staking',
  TVL: 'Nominator TVL',
  UNBONDING: 'Unbonding period',
  BALANCE: 'Balance',
  VALUE: 'Value',
}

export const COLUMNS = {
  NAME: {
    id: 'dapp-staking-data-nominator-name',
    code: COLUMN_CODES.NAME
  },
  TVL: {
    id: 'dapp-staking-data-nominator-tvl',
    code: COLUMN_CODES.TVL
  },
  UNBONDING: {
    id: 'dapp-staking-data-nominator-unbonding',
    code: COLUMN_CODES.UNBONDING
  },
  BALANCE: {
    id: 'dapp-staking-data-nominator-balance',
    code: COLUMN_CODES.BALANCE
  },
  VALUE: {
    id: 'dapp-staking-data-nominator-value',
    code: COLUMN_CODES.VALUE,
  },
};

export const tableData = new schema.Entity('tableData', {
  columnIds: [tableDataColumn],
  rowIds: [tableDataRow]
}, {
  idAttribute: value => `tableData-${value.id}`,
  processStrategy: value => ({
    id: `tableData-${value.id}`,
    columnIds: Object.values(COLUMNS),
    rowIds: value.appStaking.apps,
  }),
});