import {PublicKey} from '@solana/web3.js';
import { decodeAddress, encodeAddress } from '@polkadot/keyring';

export const shortenWallet = (wallet: string) => `${wallet.slice(0, 6)}...${wallet.slice(-4)}`;

export const isEthereumAddress = (walletId: string) => /^0x[a-fA-F0-9]{40}$/.test(walletId);
const isSolanaAddress = async (walletId: string) => {
  const {PublicKey} = await import('@solana/web3.js');
  try {
    return Boolean(new PublicKey(walletId));
  } catch (err) {
    return false;
  }
};
export const isTerraAddress = (walletId: string) => /^(terra1[a-z0-9]{38})$/.test(walletId);
export const isBitcoinAddress = (walletId: string) => /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/.test(walletId);
export const isCosmosAddress = (walletId: string) => /^(cosmos1[a-z0-9]{38})$/.test(walletId);
const isSubstrateAddress = async (walletId: string) => {
  const {decodeAddress, encodeAddress} = await import('@polkadot/keyring');
  try {
    encodeAddress(decodeAddress(walletId));
    return true
  } catch (error) {
    return false
  }
}

export const isSupportedAddress = async (walletId: string) => {
  if (
    isEthereumAddress(walletId)
    || isTerraAddress(walletId)
    || isBitcoinAddress(walletId)
    || isCosmosAddress(walletId)
  ) {
    return true;
  }

  const [
    isSolana,
    isSubstrate,
  ] = await Promise.all([
    isSolanaAddress(walletId),
    isSubstrateAddress(walletId),
  ]);

  return isSolana || isSubstrate;
};

export const prepareWalletId = (walletId: string) =>
  (isEthereumAddress(walletId) || isTerraAddress(walletId))
    ? walletId.toLowerCase()
    : walletId;
