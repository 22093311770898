import createStore, {Heap} from '@fidi/concrete/store';
import {MODE} from '@fidi/concrete/constants/mode';

const initialHeap: Heap = {
  walletId: undefined,
  externalWalletId: new URL(window.location.href)?.searchParams?.get('id'),
  mode: MODE.PROTOCOL,
  recentWallets: [],
  swap: {
    isVisible: false,
    fromChain: undefined,
    fromToken: undefined,
  },
};

export default createStore(initialHeap);
