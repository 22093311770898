import {ApiConfig} from './index';

export type AssetVisibility = {
  chain: string,
  tokenAddress: string,
  isHidden: boolean,
}

export const saveHiddenAssets = (config: ApiConfig) => ({assets}: {assets: AssetVisibility[]}) =>
  config.request({
    method: 'PATCH',
    url: `/api/assets/hidden`,
    data: {
      assets,
    },
  });