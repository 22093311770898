import React, {useEffect, useMemo, useRef} from 'react';
import cx from 'classnames';

import {Theme, ThemeContext, THEMES} from '@/app/contexts/theme';
import Moon from '@/uikit/icons/moon.svg';
import Sun from '@/uikit/icons/sun.svg';

import * as styles from './styles.styl';
import {useLocation} from 'react-router-dom';

const RESTRICTED_PAGES: Record<string, Theme> = {
  '/giveaway/': THEMES.LIGHT,
};

const useRestrictedPages = ({currentTheme, toggleTheme}: {currentTheme: Theme, toggleTheme: () => void}) => {
  const location = useLocation();
  const forcedTheme = useMemo(() => {
    const forcedThemePattern = Object.keys(RESTRICTED_PAGES).find(prefix => location.pathname.indexOf(prefix) === 0);
    return forcedThemePattern ? RESTRICTED_PAGES[forcedThemePattern] : null;
  }, [location.pathname]);

  const savedTheme = useRef<Theme>(null);
  useEffect(() => {
    if (forcedTheme) {
      if (currentTheme !== forcedTheme) {
        savedTheme.current = currentTheme;
        toggleTheme();
      }
    } else {
      if (savedTheme.current) {
        if (savedTheme.current !== currentTheme) {
          toggleTheme();
        }
        savedTheme.current = null;
      }
    }
  }, [forcedTheme]);

  return {
    canSelectTheme: !forcedTheme,
  }
};

const DarkModeTogglerBody = ({theme, toggleTheme}: {theme: Theme, toggleTheme: () => void}) => {
  const {canSelectTheme} = useRestrictedPages({currentTheme: theme, toggleTheme});

  if (!canSelectTheme) {
    return null;
  }

  return (
    <div className={cx(styles.root, {[styles.moon]: theme === THEMES.LIGHT})} onClick={toggleTheme} data-e2e="1890e3ae-f47f-4d78-b9f4-81f40afef7aa">
      {theme === THEMES.DARK ? <Sun /> : <Moon />}
    </div>
  );
};

const DarkModeToggler = () => (
  <ThemeContext.Consumer>
    {({theme, toggleTheme}) => (<DarkModeTogglerBody theme={theme} toggleTheme={toggleTheme} />)}
  </ThemeContext.Consumer>
);

export default DarkModeToggler;
