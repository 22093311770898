import {ApiConfig} from './index';

export type GiveawayId = string;

export type GiveawayPrizeId = string;

export type GiveawayPrize = {
  id: GiveawayPrizeId;
  name: string;
};

export type Enroll = {
  isEligible: boolean;
  isEnrolled: boolean;
  wonPrizes: Array<{
    id: GiveawayPrizeId;
    txHash: string,
    claimedAt?: string;
    claimUrl: string;
  }>
};

export type Giveaway = {
  id: GiveawayId;
  name: string;
  subtitle?: string;
  linkText?: string;
  startedAt?: string;
  finishedAt?: string;
  enrollFinishedAt: string;
  prizes: GiveawayPrize[];
  enroll?: Enroll;
  claimAfterEnd?: boolean;
};

export const getGiveaways = (config: ApiConfig) =>
  ({walletId, giveawayId}: {walletId?: string, giveawayId?: GiveawayId}): Promise<{giveaways: Array<Giveaway>}> =>
    config.request({
      method: 'GET',
      url: `/api/giveaways/`,
      params: {
        walletId,
        giveawayId,
      },
    });

export const enrollInGateway = (config: ApiConfig) => async ({
  walletId,
  giveawayId,
}: {
  walletId: string,
  giveawayId: GiveawayId,
}): Promise<void> => {
  await config.request({
    method: 'POST',
    url: `/api/giveaways/${giveawayId}/enroll`,
    data: {
      walletId,
    },
  })
};

export const claim = (config: ApiConfig) => async ({
  walletId,
  giveawayId,
}: {
  walletId: string,
  giveawayId: GiveawayId,
}): Promise<void> => {
  await config.request({
    method: 'POST',
    url: `/api/giveaways/${giveawayId}/claim`,
    data: {
      walletId,
    },
  })
}
