import {GetInitialState} from '@fidi/concrete/widgets';

import {Data, Collections} from '.';

const getInitialState: GetInitialState<{}, Data, Collections> = async () => ({
  data: {
    notificationIds: [],
  },
  collections: {
    notification: {},
  },
});

export default getInitialState;
