import { useEffect } from 'react';
import { WidgetEvent, useWidgetEvents } from '@lifi/widget';
import type {Route} from '@lifi/sdk';
import {useDispatch} from 'react-redux';

import {finishSwap} from '../../actions';

const LiFiEvents = () => {
  const widgetEvents = useWidgetEvents();
  const dispatch = useDispatch();

  useEffect(() => {
    const onRouteExecutionCompleted = (route: Route) => {
      dispatch(finishSwap([route.fromAddress, route.toAddress]))
    };
    widgetEvents.on(
      WidgetEvent.RouteExecutionCompleted,
      onRouteExecutionCompleted,
    );
    return () => widgetEvents.all.clear();
  }, [widgetEvents]);

  return null;
};

export default LiFiEvents;