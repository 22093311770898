import {Action} from '@reduxjs/toolkit';
import {filter, mergeMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import { disconnect } from '@wagmi/core'

import {Api} from '@/api';
import {updateHeap} from '@/actions';
import {startConnecting as globalStartConnecting, disconnect as globalDisconnect} from '@/actions/connect'

import {Data} from '.';
import {startConnecting} from './actions'

export const onGlobalConnect = (api: Api, data$: Observable<Data>, action$: Observable<Action>) =>
  action$.pipe(
    filter(globalStartConnecting.match),
    mergeMap(() => of(startConnecting()))
  );

export const onGlobalDisconnect = (api: Api, data$: Observable<Data>, action$: Observable<Action>) =>
  action$.pipe(
    filter(globalDisconnect.match),
    mergeMap(() => {
      disconnect();
      return of(updateHeap({
        walletId: undefined,
        externalWalletId: undefined,
      }));
    }),
  );