
export const EVENT_CATEGORIES = {
  ACCESS: 'Access',
  TRANSITION: 'Transition',
  VISIBILITY: 'Visibility',
  VISIBILITY_STATE: 'VisibilityState',
  ACTION: 'Action',
  STACK: 'Stack',
  HISTORY: 'History',
  CONNECTION: 'Connection',
  GIVEAWAY: 'Giveaway',
};

type EventProps = {
  name: string,
  category: string,
  label: string,
  value: number,
}
export const gtagEvent = <A extends EventProps>({ name, category, label, value, ...rest }: A) => {
  window.gtag('event', name, {
    event_category: category,
    event_label: label,
    value: value,
    ...rest,
  })
}
