import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {createPortal} from 'react-dom';
import { createRoot } from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import cx from 'classnames';
import {configureChains, createClient, defaultChains, WagmiConfig} from 'wagmi';
import {publicProvider} from 'wagmi/providers/public';
import {MetaMaskConnector} from 'wagmi/connectors/metaMask';
import {CoinbaseWalletConnector} from 'wagmi/connectors/coinbaseWallet';
import {WalletConnectConnector} from 'wagmi/connectors/walletConnect';
import {TalismanConnector} from '@talismn/wagmi-connector';

import LiFi from '@/widgets/LiFi';
import {ThemeContext, THEMES} from '@/app/contexts/theme';
import {DARK_MODE} from '@/constants/localStorage';
import {isLiFiEnabled} from '@/utils/featureToggle';
import {SubWalletConnector} from '@/widgets/ConnectionManager/components/View/wallets/SubWallet';

import store from './store';
import App from './app';

import * as styles from './styles.styl';

Sentry.init({
  dsn: 'https://66a7944cfdbc4f8280804a71f04295d9@o1262124.ingest.sentry.io/6440565',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  enabled: process.env.NODE_ENV !== 'development',
});

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(dayjs.tz.guess());

const container = document.getElementById('root');
const root = createRoot(container);

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, provider, webSocketProvider } = configureChains(defaultChains, [
  publicProvider(),
])

// Set up client
const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'FiDi',
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
    new SubWalletConnector({
      chains,
    }),
    new TalismanConnector(),
  ],
  provider,
  webSocketProvider,
})

const View = () => {
  const [contentElement, setContentElement] = useState<Element | null>(null);
  useEffect(() => {
    if (isLiFiEnabled) {
      setTimeout(() => {
        const contentRootEl = document.querySelector('div[data-content="content"]');
        setContentElement(contentRootEl);
      }, 3000);
    }
  }, []);

  const savedMode = window.localStorage.getItem(DARK_MODE);
  const defaultDark = (savedMode && savedMode === 'dark') ?? window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useState(defaultDark ? THEMES.DARK : THEMES.LIGHT);
  const toggleTheme = useCallback(() => {
    if (theme === THEMES.DARK) {
      setTheme(THEMES.LIGHT);
      window.localStorage.setItem(DARK_MODE, 'light');
    } else {
      setTheme(THEMES.DARK);
      window.localStorage.setItem(DARK_MODE, 'dark');
    }
  }, [setTheme, theme]);
  const value = useMemo(() => {
    return {
      theme,
      toggleTheme,
    }
  }, [theme, toggleTheme]);
  const className = cx(styles.root, {
    [styles.dark]: theme === THEMES.DARK,
  })

  return (
    <Provider store={store}>
      <WagmiConfig client={client}>
        <ThemeContext.Provider value={value}>
          <div className={className}>
            {
              contentElement &&
              createPortal(
                <LiFi />,
                contentElement
              )
            }
            <Router>
              <App />
            </Router>
          </div>
        </ThemeContext.Provider>
      </WagmiConfig>
    </Provider>
  )
}

root.render(<View />);
