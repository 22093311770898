import {schema} from 'normalizr';

import {COLUMNS} from '../../tableData/schemas/collators';

export const tableDataRow = new schema.Entity('tableDataRow', {}, {
  idAttribute: (value, parent) => `${parent.id}-row-${value.id}`,
  processStrategy: (value, parent) => {
    return {
      id: `${parent.id}-row-${value.id}`,
      columns: [COLUMNS.APY, COLUMNS.BALANCE, COLUMNS.COLLATORS, COLUMNS.VALUE, COLUMNS.NOMINATOR, COLUMNS.RANK].map(column => column.id),
      values: [
        {
          text: value.apy
        },
        {
          text: value.balance,
        },
        {
          text: value.name,
        },
        {
          text: value.value,
        },
        {
          rank: value.nominatorRank,
          threshold: value.nominatorRankBottomThreshold,
        },
        {
          rank: value.rank,
          threshold: value.rankBottomThreshold,
        },
      ]
    }
  },
});