import {WidgetReducer} from '@fidi/concrete/widgets';

import {startConnecting, stopConnecting} from './actions';

import {Data} from '.';

const reducer: WidgetReducer<Data> = (builder =>
  builder.addCase(startConnecting, (data) => {
    data.connectingInProgress = true;
  }).addCase(stopConnecting, (data) => {
    data.connectingInProgress = false;
  })
);

export default reducer;
