import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {Notification} from '@fidi/concrete/entities/notification';

import {hideNotification} from '../../actions';
import * as styles from './styles.styl';

const VISIBLE_FOR = 5000;

const NotificationView = ({id, msg}: Notification) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => dispatch(hideNotification(id)), VISIBLE_FOR)
  }, []);
  return (
    <div className={styles.root}>
      {msg}
    </div>
  )
}

export default NotificationView;