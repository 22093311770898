import {GetInitialState} from '@fidi/concrete/widgets';
import {Data, Collections} from '.';

const getInitialState: GetInitialState<{}, Data, Collections> = async () => ({
  data: {
    connectingInProgress: false,
  },
  collections: {},
});

export default getInitialState;
