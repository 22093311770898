import {Action} from '@reduxjs/toolkit'
import {Observable, from, catchError, of} from 'rxjs';
import {filter, mergeMap} from 'rxjs/operators'

import {Api} from '@/api';
import {updateWidgetsGroup} from '@fidi/concrete/actions';
import {SWAPPABLE_ID_GROUP} from '@fidi/concrete/constants/widget';
import {uniq} from '@/utils/arrays';
import {prepareWalletId} from '@/utils/wallet';

import {Data} from '.';
import {finishSwap} from './actions';

export const onSuccess = (api: Api, data$: Observable<Data>, action$: Observable<Action>) =>
  action$.pipe(
    filter(finishSwap.match),
    mergeMap(({payload: walletIds}) => {
      return from(Promise.all(
        uniq(walletIds).map(walletId => api.clearWalletCache({walletId: prepareWalletId(walletId)}))
      ))
        .pipe(
          mergeMap(() => of(updateWidgetsGroup(SWAPPABLE_ID_GROUP))),
          catchError(() => of(updateWidgetsGroup(SWAPPABLE_ID_GROUP)))
        )
    }),
    catchError(() => of(updateWidgetsGroup(SWAPPABLE_ID_GROUP)))
  );
