import { schema } from 'normalizr';
import {wallet} from '../wallet/schema';

export const stack = new schema.Entity('stack', {
  wallets: [wallet],
}, {
  processStrategy: value => ({
    id: value.id,
    name: value.name,
    wallets: value.wallets.map((walletHash: string) => ({
      id: walletHash,
      hash: walletHash,
    })),
  }),
});
