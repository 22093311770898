import {schema} from 'normalizr';
import {asset} from '../../asset/schema';
import {assetList} from '../../assetList/schema';
import {USD_ID} from '../../currency/constants';
import {namedAllocation} from '../../allocation/schema';
import {tableData as collatorsTableData} from '../../tableData/schemas/collators';
import {tableData as appStakingTableData} from '../../tableData/schemas/appStaking';

export const assetListItemPair = new schema.Entity('assetListItem', {
  assetIds: [asset],
  rewards: assetList,
  additional: {
    parts: {
      allocationId: namedAllocation,
    },
    collatorsDataId: collatorsTableData,
    appStaking: {
      dataId: appStakingTableData,
    },
  },
}, {
  idAttribute: value => value.map(({id}: {id: string}) => id).join('-'),
  processStrategy: value => {
    const assets = value.map((t: any) => ({
      id: `${t.chain}-${t.id}`,
      symbol: t.optimized_symbol || t.display_symbol || t.symbol,
      name: t.name,
      price: t.price,
      priceSource: t.priceSource,
      logoUrl: t.logo_url || t.logoUrl,
      chain: t.chain,
      chainId: t.chainId,
      address: t.address,
    }));

    const parts = value?.additional?.find(({type}) => type === 'parts');
    const collators = value?.additional?.find(({type}) => type === 'collators');
    const appStaking = value?.additional?.find(({type}) => type === 'appStaking');
    const hasAdditional = parts || collators || appStaking;

    const id = value.map(({id}: {id: string}) => id).join('-')
    return {
      id,
      assetIds: assets,
      rewards: value.rewards?.map((t: any) => ({
        ...t,
        id: `r-${id}-${t.id}-${t.amount}`,
      })),
      value: {
        [USD_ID]: value.reduce((acc: number, t: any) => acc + t.price * t.amount, 0),
//          + (value.rewards?.reduce((acc: number, t: any) => acc + t.price * t.amount, 0) || 0),
      },
      balance: assets.reduce((acc: any, a: any, i: number) => Object.assign(acc, {[a.id]: value[i].amount}), {}),
      additional: hasAdditional && {
        parts: parts && ({
          allocationId: {
            assets: parts.parts,
            id: Math.random(),
          },
          name: parts.name,
          semanticName: parts.semanticName,
          semanticValue: parts.semanticValue,
          unit: parts.unit,
        }),
        collatorsDataId: collators && {
          id: Math.random(),
          ...collators
        },
        appStaking: appStaking && {
          dataId: {
            id: Math.random(),
            ...appStaking,
          },
          evm: appStaking.appStaking.evm,
          free: appStaking.appStaking.free,
          staking: appStaking.appStaking.staking,
          vesting: appStaking.appStaking.vesting,
        },
        unit: collators?.unit || parts?.unit || appStaking?.unit,
      },
    };
  },
});
