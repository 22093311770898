import {Observable} from 'rxjs';
import {filter, mergeMap} from 'rxjs/operators';
import {Action} from '@reduxjs/toolkit';

import {Api} from '@fidi/concrete/api';
import {notify, updateCollections} from '@fidi/concrete/actions';

import {Data} from '.';
import {showNotification} from './actions';

export const onNotify = (api: Api, data$: Observable<Data>, action$: Observable<Action>) =>
  action$.pipe(
    filter(notify.match),
    mergeMap(({payload: notification}) => {
      const timestamp = Date.now()
      const notificationId = `${timestamp}-${Math.random()}`;
      return [
        updateCollections({
          notification: {
            [notificationId]: {
              id: notificationId,
              timestamp,
              ...notification,
            },
          }
        }),
        showNotification(notificationId)
      ];
    })
  );