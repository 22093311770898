import React, {useCallback} from 'react';
import cx from 'classnames';

import Header from './components/Header';
import * as styles from './styles.styl';

type Props = {
  children: React.ReactNode;
  rounded?: boolean;
  border?: boolean;
  header?: string;
  headerIcon?: React.ReactNode;
  onClose?: () => void;
  className?: string;
  contentClassName?: string;
};

const Module = ({children, rounded = true, border = false, header, headerIcon, onClose, className, contentClassName}: Props) => {
  const _close = useCallback(e => {
    e.cancelBubble = true;
    e.stopPropagation();
    onClose();
  }, [onClose]);
  return (
    <div className={cx(styles.root, className, {[styles.rounded]: rounded, [styles.border]: border})}>
      {header && <Header title={header} icon={headerIcon} onClose={onClose && _close} />}
      <div className={contentClassName}>
        {children}
      </div>
    </div>
  );
}

export default Module;
