import {schema} from 'normalizr';

export const tableDataColumn = new schema.Entity('tableDataColumn', {}, {
  idAttribute: value => value.id,
  processStrategy: value => {
    return {
      id: value.id,
      code: value.code,
    };
  }
});