import { schema } from 'normalizr';

export const nftCollection = new schema.Entity('nftCollection', {}, {
  idAttribute: value => nftCollectionId(value),
  processStrategy: value => ({
    id: nftCollectionId(value),
    chainId: value.chain,
    contractId: value.contract_id,
    name: value.contract_name,
    description: value.description,
  }),
});

const nftCollectionId = value => value.collection_id;
