import {GetInitialState} from '@fidi/concrete/widgets';

import {Data, Collections, Props} from '.';

const getInitialState: GetInitialState<Props, Data, Collections> = async () => {
  return {
    data: {},
    collections: {},
  };
};

export default getInitialState;
