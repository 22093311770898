import React from 'react';

import {describeWidget} from '@fidi/concrete/widgets';

import api from '@/api';
import ErrorPlaceholder from '@/components/ErrorPlaceholder';

import getInitialState from './getInitialState';
import view from './view';
import reducer from './reducer';
import * as epics from './epics';

export type Props = {}

export type Data = {};

export type Collections = {};

export type State = {
  collections: Collections;
};

const LiFi = describeWidget<Props, Data, Collections>({
  name: 'LiFi',
  getInitialState,
  view,
  reducer,
  placeholder: ({children}) => <>{children}</>,
  epics: Object.values(epics),
  api,
  errorPlaceholder: ErrorPlaceholder,
});

export default LiFi;
