import {useEffect, useRef} from 'react';
import isEqual from 'lodash.isequal';

import {shallowEqual} from './objects';

export const useComplexEffect = (effectFunc: () => void, strictDeps: Object[] = [], shallowDeps: Object[] = [], deepDeps: Object[] = []) => {
  const isFirst = useRef(true);

  const prevStrictDeps = useRef(strictDeps);
  const prevShallowDeps = useRef(shallowDeps);
  const prevDeepDeps = useRef(deepDeps);

  useEffect(() => {
    let shouldExecute = isFirst.current;
    isFirst.current = false;

    // strict
    if (!shouldExecute) {
      for (let i = 0; i < prevStrictDeps.current.length; i++) {
        if (prevStrictDeps.current[i] !== strictDeps[i]) {
          shouldExecute = true;
          break;
        }
      }
    }

    // shallow
    if (!shouldExecute) {
      for (let i = 0; i < prevShallowDeps.current.length; i++) {
        if (!shallowEqual(prevShallowDeps.current[i], shallowDeps[i])) {
          shouldExecute = true;
          break;
        }
      }
    }

    // deep
    if (!shouldExecute) {
      for (let i = 0; i < prevDeepDeps.current.length; i++) {
        if (!isEqual(prevDeepDeps.current[i], deepDeps[i])) {
          shouldExecute = true;
          break;
        }
      }
    }

    prevStrictDeps.current = strictDeps;
    prevShallowDeps.current = shallowDeps;
    prevDeepDeps.current = deepDeps;

    if (shouldExecute) {
      return effectFunc();
    }
  }, [...strictDeps, ...shallowDeps, ...deepDeps]);
}
