import { schema } from 'normalizr';
import {assetListItem} from '../assetListItem/schema/assetListItem';

const getId = (value: {id: string}[]) => value.map(({id}: {id: string}) => id).join('-');

export const assetList = new schema.Entity('assetList', {
  items: [assetListItem],
}, {
  idAttribute: getId,
  processStrategy: value => ({
    id: getId(value),
    items: value,
  }),
});
