import {normalize, schema} from 'normalizr';
import {USD_ID} from '../currency/constants';
import {coin} from '../coin/schema';
import {HistoryItemCollection, HistoryItemId} from '.';
import {CoinCollection} from '../coin';

export const historyItemPiece = new schema.Entity('historyItemPiece', {
  coinId: coin,
}, {
  processStrategy: value => {
    const {token, ...rest} = value;
    return ({
      ...rest,
      coinId: token
    })
  },
});

export const historyItem = new schema.Entity('historyItem', {
  sends: [historyItemPiece],
  receives: [historyItemPiece],
}, {
  idAttribute: value => `${value.id}-${value?.sends?.length || 0}-${value?.receives?.length || 0}`,
  processStrategy: value => ({
    id: `${value.id}-${value?.sends?.length || 0}-${value?.receives?.length || 0}`,
    transactionId: value.id,
    externalId: value.external_id,
    title: value.title,
    createdAt: value.created_at,
    sends: value.sends.map((o: any) => ({...o, id: Math.random()})),
    receives: value.receives.map((o: any) => ({...o, id: Math.random()})),
    fee: value.fee && {
      [USD_ID]: value.fee.usd,
    },
  }),
});

export const normalizeHistoryItems = (data: any): {
  result: HistoryItemId[];
  entities:
    & HistoryItemCollection
    & CoinCollection
} => {
  const {result, entities} = normalize(data, [historyItem]);

  for (const historyItemId of result) {
    const e = entities.historyItem[historyItemId];
    e.sends = e.sends.map((pieceId: string) => entities.historyItemPiece[pieceId]);
    e.receives = e.receives.map((pieceId: string) => entities.historyItemPiece[pieceId]);
  }

  return {
    result,
    entities: {
      historyItem: entities.historyItem,
      // @ts-ignore
      coin: entities.coin,
    },
  };
};
