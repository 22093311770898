import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {stopConnecting} from './actions';
import ConnectView from './components/View';
import * as styles from './styles.styl';
import {Data} from '.';

const Connect = ({connectingInProgress}: Data) => {
  const dispatch = useDispatch();
  const close = useCallback(() => dispatch(stopConnecting()), [dispatch, stopConnecting]);
  return (
    <div className={!connectingInProgress ? styles.hidden : undefined}>
      <div className={styles.paranja} onClick={close} />
      <div className={styles.wrapper}>
          <ConnectView close={close} />
      </div>
    </div>
  );
}

export default Connect;
