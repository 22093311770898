import { schema } from 'normalizr';

export const protocol = new schema.Entity('protocol', {}, {
  processStrategy: value => ({
    id: value.id,
    name: value.name,
    logoUrl: value.logo_url,
    siteUrl: value.site_url,
    totalValue: value.net_usd_value,
  }),
});
