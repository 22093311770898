import React, {useRef} from 'react';

import Notifications from '@/widgets/Notifications';
import ConnectionManager from '@/widgets/ConnectionManager';
import {CONTENT_ID} from '@/constants/html';

import Header from './Header';
import Footer from './Footer';
import {Context} from './contentContext';

import StaticTopNotificationBanner from '@/components/StaticTopNotificationBanner';

import * as styles from './styles.styl';

type Props = {
  children: React.ReactNode;
};

const Layout = ({children}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  return (
    <div className={styles.root} data-e2e="45e2ca1e-aa7d-4016-9f85-433530fb9cbf">
        <Context.Provider value={contentRef}>
          <StaticTopNotificationBanner />
          <div className={styles.header}>
            <Header />
          </div>
          <div className={styles.contentWrapper} ref={contentRef} data-content="content">
            <div className={styles.content} data-scrollable={true} id={CONTENT_ID}>
              <div className={styles.main}>
                {children}
              </div>
              <Footer />
            </div>
            <div className={styles.connectionManager}>
              <ConnectionManager />
            </div>
          </div>
          <div className={styles.notifications}>
            <Notifications />
          </div>
        </Context.Provider>
        <div id="popups" />
    </div>
  );
}

export default Layout;
